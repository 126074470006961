import React, { useEffect, useState } from 'react';
import './MyCustomChatMessage.css'
import { getChatbot } from "../api/service";



const MyCustomChatMessage = (props) => {
    const [time, setTime] = useState(new Date());
    const [read, setRead] = useState(new Date());
   
    const [color, setColor] = useState('#1972F5');
 
    // Fonction pour récupérer les données du chatbot
    const fetchChatbotData = async (chatBotId) => {
        try {
            const response = await getChatbot(chatBotId);
            const chatbotData = response.data;
            console.log(chatbotData);
            
            if (chatbotData) {
                setColor(chatbotData.modules.chatbot.color || '#1972F5');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données du chatbot :', error);
        }
    };



    useEffect(() => {
        const chatBotId = window.location.href.includes('chatbot_id=')
            ? window.location.href.split('chatbot_id=')[1].split('&')[0]
            : null;

        if (chatBotId) {
            fetchChatbotData(chatBotId);
        }
    }, []);

    useEffect(() => {
        setTime(new Date());
        setRead(new Date());
    }, []);

    return (
        <div className={'message'}>
            <div className={'message-con-bot'} style={{background: color}} dangerouslySetInnerHTML={{ __html: props.message }}></div>
            <div className={'timeFloat'}>
                <span style={{color: '#3974d8'}}>{time && ' Envoyé le ' + time.toLocaleDateString() + ' à ' + time.toLocaleTimeString()}</span>
                {/*<br/>*/}
                {/*<b style={{color: '#01b75e'}}>{read && ' Lu le ' + read.toLocaleDateString() + ' à ' + read.toLocaleTimeString()}</b>*/}
            </div>
        </div>
    )
}

export default MyCustomChatMessage
