import axios from 'axios'

const base_url = 'https://api.mychatassist.fr'

export const getEntreprise = async (id) => {

  try {
    return await axios({
      method: 'get',
      url: `${base_url}/entreprise/${id}`
    })
  } catch (error) {
    // console.error(error)
  }

}

export const getChatbot = async (id) => {

  try {
    return await axios({
      method: 'get',
      url: `${base_url}/chatbot/${id}`
    })
  } catch (error) {
    // console.error(error)
  }

}

export const getHistory = async (id) => {

  try {
    return await axios({
      method: 'get',
      url: `${base_url}/chat/${id}`
    })
  } catch (error) {
    // console.error(error)
  }
}

export const createQt = async (id) => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        'chatbot_id' : id
      }
    });
  } catch (error) {
    console.error(error)
  }

}

export const createIntroMsg = async (id, data) => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat/${id}/ai-message`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    });
  } catch (error) {
    console.error(error)
  }

}

export const postQuestions = async (idQt, qt) => {

  try {
    return await axios({
      method: 'post',
      url: `${base_url}/chat/${idQt}/message`,
      data: qt
    })
  } catch (error) {
    console.error(error)
  }

}

export const inviteChat = async (idQt) => {

  try {
    return await axios({
      method: 'put',
      url: `${base_url}/chat/${idQt}/invite`,
      data: {}
    })
  } catch (error) {
    console.error(error)
  }

}

export const closeChat = async (idQt) => {

  try {
    return await axios({
      method: 'put',
      url: `${base_url}/chat/${idQt}/end`,
      data: {}
    })
  } catch (error) {
    console.error(error)
  }

}

export const getForm = async (idQt) => {

  try {
    return await axios({
      method: 'get',
      url: `${base_url}/chat/${idQt}`
    })
  } catch (error) {
    console.error(error)
  }

}

export const getChatById = async(id) => {
  
  try {
    return await axios({
      method: 'get',
      url: `${base_url}/chat/${id}`
    })
  } catch (error) {
    console.error(error)
  }
}
