import React from 'react';
import { Button, Box } from '@mui/material';
import { createChatBotMessage } from 'react-chatbot-kit'; // Assurez-vous d'importer cette fonction si ce n'est pas déjà fait

const RestartButton = (props) => {
    const { setState, payload, states } = props;
    const { content } = payload;
    
    const chat_bot_id = window.location.href.includes('chatbot_id=') 
        ? window.location.href.split('chatbot_id=')[1].split('&')[0] 
        : null;

    const handleRestart = () => {

        const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
        delete chatbotsData[chat_bot_id]['chat_id']
        delete chatbotsData[chat_bot_id]['chat_messages']
        // Sauvegarde dans localStorage
        localStorage.setItem('chatbots', JSON.stringify(chatbotsData));

        window.location.reload()



        

      
    };
    
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',  // Centrer le bouton horizontalement
                padding: '20px',           // Ajouter un peu d'espacement autour
            }}
        >
            <Button
                onClick={handleRestart}
                variant="contained"         // Utilisation du style 'contained' pour un bouton plein
                color="primary"             // Couleur primaire
                size="medium"               // Taille moyenne du bouton
                sx={{
                    padding: '8px 16px',    // Espacement interne simple
                    borderRadius: '8px',    // Coins légèrement arrondis
                    textTransform: 'none',  // Ne pas mettre le texte en majuscules
                }}
            >
                {'Relancer un chat'}
            </Button>
        </Box>
    );
};

export default RestartButton;
