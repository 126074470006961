import React, { useState, useEffect } from 'react';
import { getChatbot, getEntreprise } from "../api/service";
import './myHeader.css';

const MyHeader = () => {
    const [openNav, setOpenNav] = useState(false);
    const [color, setColor] = useState('#1972F5');
    const [name, setName] = useState('MCA');
    const [logo, setLogo] = useState(null);

    // Fonction pour récupérer les données du chatbot
    const fetchChatbotData = async (chatBotId) => {
        try {
            const response = await getChatbot(chatBotId);
            const chatbotData = response.data;
            console.log(chatbotData);
            
            if (chatbotData) {
                setColor(chatbotData.modules.chatbot.color || '#1972F5');
                setLogo(chatbotData.modules.chatbot.logo);
                if (chatbotData.entreprise_id) {
                    await fetchEntrepriseData(chatbotData.entreprise_id);
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données du chatbot :', error);
        }
    };

    // Fonction pour récupérer les données de l'entreprise
    const fetchEntrepriseData = async (entrepriseId) => {
        try {
            const response = await getEntreprise(entrepriseId);
            const entrepriseData = response.data;
            if (entrepriseData) {
                setName(entrepriseData.nom);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données de l\'entreprise :', error);
        }
    };

    useEffect(() => {
        const chatBotId = window.location.href.includes('chatbot_id=')
            ? window.location.href.split('chatbot_id=')[1].split('&')[0]
            : null;

        if (chatBotId) {
            fetchChatbotData(chatBotId);
        }
    }, []);

    return (
        <div style={{ backgroundColor: color, position: 'relative' }}>
        <div style={{ backgroundColor: color, padding: "25px", display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <div className="logo-container">
                {logo ? (
                    <img src={logo} alt="Logo" className="logo-image" />
                ) : (
                    <img src="/assets/chat.svg" alt="Default Logo" className="logo-image" />
                )}
                <div className="status-indicator"></div>
            </div>
            <div style={{ color: '#ffffff', textAlign: "center", padding: '0 10px', fontSize: '16px', fontWeight: 'bold' }}>
                {name}
            </div>
        </div>
        <div className={'navBar'} style={{ display: openNav ? 'flex' : 'none' }}>
            {/* Contenu de la barre de navigation */}
        </div>
    </div>
    );
};

export default MyHeader;
