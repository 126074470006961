import React, {useState, useEffect}  from 'react'
import { Avatar } from '@mui/material'
import { getChatbot } from "../api/service";
import './myAvatar.css'


const MyAvatar = () => {

    const [logo, setLogo] = useState(null);


    

    // Fonction pour récupérer les données du chatbot
    const fetchChatbotData = async (chatBotId) => {
        try {
            const response = await getChatbot(chatBotId);
            const chatbotData = response.data;
           
            if (chatbotData) {
                setLogo(chatbotData.modules.chatbot.logo);
              
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données du chatbot :', error);
        }
    };

    // Fonction pour récupérer les données de l'entreprise
  

    useEffect(() => {
        const chatBotId = window.location.href.includes('chatbot_id=')
            ? window.location.href.split('chatbot_id=')[1].split('&')[0]
            : null;

        if (chatBotId) {
            fetchChatbotData(chatBotId);
        }
    }, []);


    return (
        <Avatar
        alt="bot"
        src={logo ? logo : "/assets/chat.svg"}
        sx={{ width: 35, height: 35 }}
      />
    )
}

export default MyAvatar
